var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
        [
          _c("c-table", {
            ref: "processTable2",
            attrs: {
              title: "LBL0003242",
              columns: _vm.grid.columns,
              data: _vm.grid.data,
              columnSetting: false,
              isFullScreen: false,
              usePaging: false,
              expandAll: true,
              filtering: false,
            },
            on: { rowClick: _vm.rowClick },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
        [
          _c(
            "q-form",
            { ref: "editForm" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "LBL0003243" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && this.parentProcess !== null
                            ? _c("c-btn", {
                                attrs: {
                                  label: "LBL0003244",
                                  icon: "download",
                                },
                                on: { btnClicked: _vm.downloadFlow },
                              })
                            : _vm._e(),
                          _vm.editable && this.parentProcess !== null
                            ? _c("c-btn", {
                                attrs: { label: "LBL0003245", icon: "add" },
                                on: { btnClicked: _vm.addChild },
                              })
                            : _vm._e(),
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  disabled: !_vm.saveable,
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.data,
                                  mappingType: _vm.saveType,
                                  label: "LBLSAVE",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveData,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            required: true,
                            label: "LBL0003246",
                            name: "processName",
                          },
                          model: {
                            value: _vm.data.processName,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "processName", $$v)
                            },
                            expression: "data.processName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable:
                              _vm.editable &&
                              _vm.dataeditable &&
                              !_vm.updateMode,
                            required: true,
                            label: "LBL0003247",
                            name: "processCd",
                          },
                          model: {
                            value: _vm.data.processCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "processCd", $$v)
                            },
                            expression: "data.processCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            required: true,
                            label: "LBLSORTORDER",
                            name: "orderNo",
                          },
                          model: {
                            value: _vm.data.orderNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "orderNo", $$v)
                            },
                            expression: "data.orderNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-dept-multi", {
                          attrs: {
                            label: "LBL0001721",
                            editable: _vm.editable && _vm.dataeditable,
                            parentCheckDepts: _vm.data.managementDepts,
                            plantCd: _vm.data.plantCd,
                            name: "managementDepts",
                          },
                          model: {
                            value: _vm.data.managementDepts,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "managementDepts", $$v)
                            },
                            expression: "data.managementDepts",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-radio", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            comboItems: _vm.useFlagItems,
                            label: "LBLUSEFLAG",
                            name: "useFlag",
                          },
                          model: {
                            value: _vm.data.useFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "useFlag", $$v)
                            },
                            expression: "data.useFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-9" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            counter: true,
                            editable: _vm.editable && _vm.dataeditable,
                            label: "LBL0003249",
                            name: "processDesc",
                            rows: 2,
                          },
                          model: {
                            value: _vm.data.processDesc,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "processDesc", $$v)
                            },
                            expression: "data.processDesc",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            codeGroupCd: "WORK_CYCLE_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "workCycleCd",
                            label: "LBL0003250",
                          },
                          model: {
                            value: _vm.data.workCycleCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "workCycleCd", $$v)
                            },
                            expression: "data.workCycleCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            type: "number",
                            label: "LBL0003251",
                            name: "workingTime",
                          },
                          model: {
                            value: _vm.data.workingTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "workingTime", $$v)
                            },
                            expression: "data.workingTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            label: "LBL0003252",
                            name: "temperature",
                            suffix: "°C",
                          },
                          model: {
                            value: _vm.data.temperature,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "temperature", $$v)
                            },
                            expression: "data.temperature",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-upload", {
                          attrs: {
                            attachInfo: _vm.attachInfo1,
                            editable:
                              _vm.editable &&
                              _vm.attachable &&
                              _vm.dataeditable,
                            label: "LBL0003253",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }